import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import FooterBranding from '../components/footer/FooterBranding'
import Aside from '../components/Aside'

// const { location, title, children, sidelist } = this.props

const JAideMaVille = props => (
  <Layout>

    <div className="container custom-container">
            <div className="content-wrapper title-page">
            <div className="content-wrapper-inner">
                  <h1>J’aide ma ville</h1>
                  {/* <span>{props.location.hostname}{props.location.pathname}</span> */}
                  {/* <span>{props.location}</span> */}
                  {console.log(props.location)}
                  {/* <span>7 janvier 2019 |</span> */}
              </div>
            </div>
          <div className="container-inner">
          
            <div className="content-wrapper">
            <div className="content-wrapper-inner">
                <div className="content">
                
                  <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/06/city-1024x683-1.jpg" alt=""/>
                  <p>L’Association JAIDE propose de se mettre à disposition des collectivités locales situées dans les départements suivants&nbsp;: Val d’Oise (95), l’Oise (60) et les Yvelines (78).</p>

                  <p>Pour cela, nous proposons&nbsp; un catalogue d’actions&nbsp;pour accompagner les municipalités dans leur démarche de &nbsp;solidarité  et de «&nbsp;vivre-ensemble&nbsp;». </p>

                  <ul><li>Programme de « Réussite éducative » (ex : soutien scolaire, organisation de forums d’orientation, …) </li><li>Dispositif d’aide à la recherche d’emploi (ex : ateliers CV, simulation entretien d’embauche,…)</li><li>Lutte contre la fracture numérique (cours sur l’utilisation d’internet, création d’adresse mail, aide à l’utilisation des logiciels Excel/Word, initiation aux nouvelles technologies de communication, …)</li><li>Actions pour favoriser les liens intergénérationnels (ateliers et moments de détente dans les maisons de retraite, </li><li>Programme d’aide à la citoyenneté </li><li>Accompagnement  au développement de nouveaux projets initiés par les municipalités pour les communautés locales.</li></ul>
                </div>

                <aside>
                  <Aside/>
                </aside>
                

            </div>
            </div>
          </div>
      </div>
    <FooterBranding/>
  </Layout>
)

export default JAideMaVille
